import { FunctionComponent, createContext, useState } from "react";

type LayoutContextType = {
  showSensorView: boolean;
  setShowSensorView: (showSensorView: boolean) => void;
  mainSceneReady: boolean;
  setMainSceneReady: (mainSceneIsReady: boolean) => void;
};

const defaultLayoutContext: LayoutContextType = {
  showSensorView: true,
  setShowSensorView: (showSensorView: boolean) => {},
  mainSceneReady: false,
  setMainSceneReady: (mainSceneReady: boolean) => {},
};

export const LayoutContext =
  createContext<LayoutContextType>(defaultLayoutContext);

export const LayoutProvider: FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [showSensorView, setShowSensorView] = useState<boolean>(true);
  const [mainSceneReady, setMainSceneReady] = useState<boolean>(true);

  const config = {
    showSensorView,
    setShowSensorView,
    mainSceneReady,
    setMainSceneReady,
  };

  return (
    <LayoutContext.Provider value={config}>{children}</LayoutContext.Provider>
  );
};
