import {
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Canvas, RootState } from "@react-three/fiber";
import { CameraControls, useGLTF, useProgress } from "@react-three/drei";
import { Layers } from "three";

import Model from "./Model";
import Ground from "./Ground";
import Controls from "./Controls";
import CameraLookAt from "./CameraLookAt";
import {
  DEVICE_LOOK_AT,
  SCOPE_CAMERA_POSITION,
  SENSOR_LAYER,
} from "../constants";
import { DEG2RAD } from "three/src/math/MathUtils";
import { ControlsContext } from "../context/ControlsContext";
import { SettingsContext } from "../context/SettingsContext";
import { toggleCameraControlsUserInput } from "../utils";
import { LayoutContext } from "../context/LayoutContext";
import CameraPositionLogger from "./CameraPositionLogger";

useGLTF.setDecoderPath("/draco-gltf/");

const MainScene = () => {
  const [canvasState, setCanvasState] = useState<RootState | null>(null);
  const cameraControlsRef = useRef<CameraControls | null>(null);
  const { freeCameraMovement } = useContext(SettingsContext);
  const { getDevice } = useContext(ControlsContext);
  const { mainSceneReady, setMainSceneReady } = useContext(LayoutContext);
  const { progress } = useProgress();

  const deviceModel = getDevice().model;

  const { scene: device } = useGLTF(deviceModel.fileName);

  const sensorVisibleForDefaultCamera = false;

  const defaultCameraLayers = useMemo(() => {
    const layers = new Layers();

    if (sensorVisibleForDefaultCamera) {
      layers.enable(SENSOR_LAYER);
    }

    return layers;
  }, [sensorVisibleForDefaultCamera]);

  useEffect(() => {
    if (progress === 100) {
      setMainSceneReady(true);
    }
  }, [progress, setMainSceneReady]);

  return (
    <>
      <Canvas
        shadows={false}
        camera={{
          position: SCOPE_CAMERA_POSITION,
          fov: 15,
          layers: defaultCameraLayers,
        }}
        dpr={window.devicePixelRatio}
        onCreated={(state) => {
          setCanvasState(state);
        }}
        style={{ backgroundColor: "black" }}
        gl={{ antialias: true }}
      >
        <Suspense fallback={null}>
          <group
            rotation={[
              DEG2RAD * deviceModel.rotationInDegrees.x,
              DEG2RAD * deviceModel.rotationInDegrees.y,
              DEG2RAD * deviceModel.rotationInDegrees.z,
            ]}
            position={[
              deviceModel.position.x,
              deviceModel.position.y,
              deviceModel.position.z,
            ]}
          >
            <primitive object={device} />
          </group>
          <Model
            src="/hk_g28.glb"
            position={[0, 0.1, 0]}
            scale={[1, 1, 1]}
            rotation={[0, -Math.PI / 2, 0]}
          />
          <CameraLookAt position={DEVICE_LOOK_AT} enabled={true} />
          <CameraControls
            ref={cameraControlsRef}
            {...toggleCameraControlsUserInput(freeCameraMovement)}
          />
          <Ground />
          <ambientLight intensity={3} />
          <CameraPositionLogger />
        </Suspense>
      </Canvas>
      <Controls canvasState={canvasState} cameraControls={cameraControlsRef} />
    </>
  );
};
export default MainScene;
