import styled from "@emotion/styled";
import { Button as RadixButton } from "@radix-ui/themes";

export const Button = styled(RadixButton)`
  & {
    background-color: var(--white-a2);
    box-shadow: inset 0 0 0 1px var(--white-a12);
    color: var(--white-a12);
  }

  &:disabled {
    background-color: var(--black-a6);
    box-shadow: inset 0 0 0 1px var(--white-a8);
    color: var(--white-a8);
  }
`;
