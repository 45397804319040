import {
  FunctionComponent,
  ReactElement,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFrame } from "@react-three/fiber";
import { useFBO } from "@react-three/drei";
import * as THREE from "three";

import CameraVisualizer from "../CameraVisualizer";
import {
  FixedCameraMagnificationFrag,
  FixedCameraMagnificationVert,
} from "./shaders";
import { DEG2RAD } from "three/src/math/MathUtils";
import { SENSOR_LAYER, DEFAULT_LAYER } from "../../constants";

interface MagnifyingShaderMaterial extends THREE.ShaderMaterial {
  uniforms: {
    u_original_texture: { value: THREE.Texture };
    u_resolution: { value: THREE.Vector2 };
    u_zoom_factor: { value: number };
    u_zoom_center: { value: THREE.Vector2 };
    u_fallback_color: { value: THREE.Vector4 };
  };
}

type FixedCameraMagnificationProps = {
  zoom: number;
  position: THREE.Vector3Tuple;
  rotation?: THREE.Vector3Tuple | THREE.Euler;
  cameraPosition: THREE.Vector3Tuple;
  cameraHelper?: boolean;
  children: ReactElement<THREE.CircleGeometry | THREE.PlaneGeometry>;
};

const FixedCameraMagnification: FunctionComponent<
  FixedCameraMagnificationProps
> = ({ zoom, rotation, position, children, cameraPosition, cameraHelper }) => {
  const shaderMaterial = useRef<MagnifyingShaderMaterial>(null!);
  const meshRef = useRef<THREE.Mesh>(null!);
  const [refPopulated, setRefPopulated] = useState(false);

  const renderTarget = useFBO({ colorSpace: THREE.SRGBColorSpace });

  useEffect(() => {
    if (meshRef.current) {
      setRefPopulated(true);
    }
  }, []);

  const customCamera = useMemo(() => {
    if (!refPopulated) return new THREE.OrthographicCamera();

    // ToDo: computate left, right, top, bottom for orthographic camera, based on the video pane size
    // Change this if the video pane size changes
    const camera = new THREE.OrthographicCamera(-0.02, 0.02, 0.02, -0.02, 0);

    camera.layers.disable(DEFAULT_LAYER);
    camera.layers.enable(SENSOR_LAYER);

    camera.position.set(...cameraPosition);
    if (rotation) camera.rotation.set(0, 90 * DEG2RAD, 0);

    return camera;
  }, [cameraPosition, refPopulated, rotation]);

  useFrame(({ gl, scene, camera: defaultCamera }) => {
    gl.setRenderTarget(renderTarget);
    gl.render(scene, customCamera);
    gl.setRenderTarget(null);
    gl.render(scene, defaultCamera);

    if (!shaderMaterial.current) return;
    shaderMaterial.current.uniforms.u_original_texture.value =
      renderTarget.texture;
    shaderMaterial.current.uniforms.u_resolution.value.set(
      gl.getContext().drawingBufferWidth,
      gl.getContext().drawingBufferHeight,
    );
  });

  useLayoutEffect(() => {
    shaderMaterial.current.uniforms.u_zoom_factor.value = 1 / zoom;
  }, [zoom]);

  const uniforms = useRef({
    u_original_texture: { value: new THREE.Texture() },
    u_resolution: {
      value: new THREE.Vector2(),
    },
    u_zoom_factor: { value: 1 },
    u_zoom_center: {
      value: new THREE.Vector2(0.5, 0.5),
    },
    u_fallback_color: { value: new THREE.Vector4(0.0, 0.0, 0.0, 1.0) },
  });

  return (
    <>
      <CameraVisualizer camera={customCamera} enabled={cameraHelper} />
      <mesh ref={meshRef} position={position} rotation={rotation}>
        {children}
        <shaderMaterial
          ref={shaderMaterial}
          attach="material"
          fragmentShader={FixedCameraMagnificationFrag}
          vertexShader={FixedCameraMagnificationVert}
          uniforms={uniforms.current}
        />
      </mesh>
    </>
  );
};

export default FixedCameraMagnification;
