import React from "react";

type LogoProps = {
  color?: string;
  height?: string;
  onClick?: () => void;
};

const Logo: React.FunctionComponent<LogoProps> = ({
  color,
  height,
  onClick,
}) => (
  <svg id="Logo" viewBox="-5 -5 150 150" height={height} onClick={onClick}>
    <g id="Ebene_1" data-name="Ebene 1">
      <path
        d="M92.84,31.47q-1.11.21-2.19.45l-1.74.4h0A104.28,104.28,0,0,0,70.19,14.5,105.92,105.92,0,0,0,53.13,38.28l-.15.27c-.37.72-.73,1.44-1.09,2.17-.67,1.33-1.3,2.66-1.89,4-.2.43-.38.85-.55,1.27s-.4.93-.59,1.4c-.3.74-.59,1.47-.87,2.21-.2.51-.4,1-.58,1.55h0c-1.61,1.2-3.18,2.41-4.71,3.65.28-.91.56-1.82.87-2.72.14-.39.26-.79.4-1.18s.27-.79.41-1.18.27-.78.42-1.16.29-.77.44-1.17c0-.14.11-.27.16-.41.29-.77.59-1.55.91-2.31.57-1.41,1.17-2.79,1.81-4.17l.92-2c.26-.56.54-1.13.83-1.7s.61-1.22.93-1.83a2.11,2.11,0,0,1,.16-.3A115.75,115.75,0,0,1,69.67,8.61,115.64,115.64,0,0,1,92.84,31.47Z"
        transform="translate(0 -0.8)"
        fill={color}
        fillRule="evenodd"
      />
      <path
        d="M116.2,65.25q-.86-.73-1.71-1.44l-1.4-1.12h0A104.92,104.92,0,0,0,115.3,36.9a104.92,104.92,0,0,0-29.17,1.44l-.3,0-2.37.5c-1.45.32-2.9.66-4.33,1-.46.11-.9.23-1.34.36l-1.45.41-2.27.7-1.56.51h0q-2.85-.81-5.73-1.44c.88-.35,1.77-.7,2.66-1L70.62,39c.38-.14.77-.28,1.17-.41s.77-.28,1.16-.39l1.16-.4.43-.13q1.17-.39,2.37-.72c1.45-.43,2.9-.82,4.37-1.19l2.11-.51c.61-.14,1.23-.28,1.85-.4s1.33-.29,2-.42l.33-.06a114.85,114.85,0,0,1,32-1.58A116.37,116.37,0,0,1,116.2,65.25Z"
        transform="translate(0 -0.8)"
        fill={color}
        fillRule="evenodd"
      />
      <path
        d="M104.44,104.63v-4h0A104.16,104.16,0,0,0,126,86.25a105.54,105.54,0,0,0-19.32-22l-.22-.2c-.62-.53-1.24-1-1.87-1.56q-1.73-1.41-3.51-2.75c-.37-.28-.74-.56-1.12-.82s-.81-.59-1.22-.88c-.65-.46-1.3-.91-2-1.35-.45-.31-.9-.62-1.37-.9h0c-.78-1.82-1.59-3.62-2.45-5.39.82.47,1.65,1,2.45,1.45l1.07.65a10.87,10.87,0,0,1,1,.67c.3.23.7.43,1,.67l1,.68.37.25c.68.46,1.37.92,2,1.41,1.23.87,2.44,1.77,3.65,2.69l1.71,1.34,1.47,1.2c.52.42,1,.86,1.57,1.31l.26.22a116.43,116.43,0,0,1,21.17,24.11A115.16,115.16,0,0,1,104.44,104.63Z"
        transform="translate(0 -0.8)"
        fill={color}
        fillRule="evenodd"
      />
      <path
        d="M66.43,120c.6-.44,1.2-.89,1.77-1.35l1.41-1.12h0a104.08,104.08,0,0,0,24.56,7.92,106.93,106.93,0,0,0,5.08-28.86V89.34A10.54,10.54,0,0,0,99.19,88c-.06-.46,0-1-.07-1.52-.05-.79-.11-1.58-.17-2.37a13,13,0,0,0-.15-1.64h0c.94-1.74,1.83-3.51,2.68-5.28.14.94.28,1.88.4,2.83,0,.41.11.83.15,1.24s.1.82.14,1.24.09.82.12,1.23.08.82.11,1.24v.44c.07.82.14,1.65.17,2.48.1,1.51.15,3,.18,4.54v6.08a1.93,1.93,0,0,1,0,.34,116.77,116.77,0,0,1-5.58,31.65A115.36,115.36,0,0,1,66.43,120Z"
        transform="translate(0 -0.8)"
        fill={color}
        fillRule="evenodd"
      />
      <path
        d="M30.78,99.7c.72.2,1.44.38,2.16.55s1.16.28,1.75.4h0a105.29,105.29,0,0,0,9.14,24.2,105.87,105.87,0,0,0,25.66-14c.09,0,.16-.11.25-.17.65-.49,1.28-1,1.92-1.48,1.17-.92,2.33-1.86,3.46-2.82a12.93,12.93,0,0,0,1-.91l1.14-1c.59-.53,1.17-1.07,1.74-1.61.4-.37.8-.75,1.19-1.14h0c1.94-.36,3.87-.75,5.79-1.19-.65.7-1.29,1.4-2,2.08l-.87.89c-.29.3-.58.59-.88.88s-.58.59-.88.86-.59.57-.9.86c-.1.11-.22.2-.32.31-.6.57-1.2,1.13-1.82,1.68-1.13,1-2.26,2-3.43,3l-1.69,1.38c-.48.39-1,.79-1.48,1.17l-1.53,1.16-.27.21a116.69,116.69,0,0,1-28.14,15.35A116.67,116.67,0,0,1,30.78,99.7Z"
        transform="translate(0 -0.8)"
        fill={color}
        fillRule="evenodd"
      />
      <path
        d="M24.34,59.11c.3.68.6,1.37.92,2s.51,1.09.78,1.62h0A104.73,104.73,0,0,0,12.88,85,105.68,105.68,0,0,0,39.79,96.43l.29.09,2.35.59,4.36,1c.46.09.91.18,1.36.25l1.49.26q1.17.19,2.34.36c.54.08,1.08.17,1.63.22h0q2.23,2,4.54,3.8c-.94-.08-1.89-.15-2.83-.24l-1.25-.13-1.23-.15a8.29,8.29,0,0,1-1.22-.14l-1.23-.17L50,102.1c-.81-.11-1.63-.23-2.45-.38-1.49-.24-3-.52-4.45-.83l-2.12-.47c-.61-.13-1.23-.27-1.84-.43l-2-.5-.32-.09A116.31,116.31,0,0,1,7.28,86.86,115.4,115.4,0,0,1,24.34,59.11Z"
        transform="translate(0 -0.8)"
        fill={color}
        fillRule="evenodd"
      />
      <path
        d="M52,28.74c-.35.66-.69,1.33-1,2s-.53,1.08-.78,1.62h0a104.07,104.07,0,0,0-25.55,3.56,106.61,106.61,0,0,0,7.9,28.22.9.9,0,0,0,.11.28c.33.74.67,1.47,1,2.21.63,1.35,1.29,2.69,2,4,.21.42.42.82.65,1.22s.48.89.72,1.33c.39.7.78,1.39,1.18,2.07.27.47.55,1,.84,1.41h0c-.09,2-.13,4-.13,5.92-.54-.78-1.07-1.57-1.58-2.37-.22-.35-.46-.7-.68-1.06s-.44-.69-.65-1-.45-.7-.65-1l-.64-1.07c-.08-.13-.14-.26-.22-.39-.42-.71-.84-1.42-1.23-2.15-.74-1.33-1.44-2.66-2.13-4l-1-2c-.27-.56-.54-1.14-.8-1.72s-.57-1.23-.85-1.87c0-.11-.1-.21-.14-.31a116.82,116.82,0,0,1-8.67-31A115.07,115.07,0,0,1,52,28.74Z"
        transform="translate(0 -0.8)"
        fill={color}
        fillRule="evenodd"
      />
      <polygon
        points="69.72 1.67 97.03 14.87 124.33 28.07 131.08 57.73 137.82 87.39 118.92 111.17 100.03 134.96 69.72 134.96 39.41 134.96 20.52 111.17 1.62 87.39 8.37 57.73 15.11 28.07 42.42 14.87 69.72 1.67 69.72 1.67"
        fill="none"
        stroke={color}
        strokeWidth="3px"
        strokeMiterlimit="10"
      />
    </g>
    <g id="Ebene_2" data-name="Ebene 2">
      <path
        d="M67.89,61.89l-.19,22H63.11V74.66H54.7v9.23H50.11l-.09-22h4.59v9h8.51v-9Zm2.81,0H89.43V65.7L76.7,83.89H70.77L84,65.66H70.65V61.89Z"
        transform="translate(0 -0.8)"
        fill={color}
        fillRule="evenodd"
      />
    </g>
  </svg>
);

export default Logo;
