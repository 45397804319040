import { Canvas } from "@react-three/fiber";
import { FunctionComponent, useContext, useMemo } from "react";
import { OrthographicCamera } from "@react-three/drei";
import * as THREE from "three";

import Sensor from "./Sensor";
import { SENSOR_LAYER } from "../constants";
import { ControlsContext } from "../context/ControlsContext";
import { SettingsContext } from "../context/SettingsContext";

type SensorSceneProps = {
  sensorHeight?: number;
};

const SensorScene: FunctionComponent<SensorSceneProps> = ({
  sensorHeight = 1,
}) => {
  const { playVideo, getFilter } = useContext(ControlsContext);
  const { sensorVideoSrc } = useContext(SettingsContext);
  const { getDevice } = useContext(ControlsContext);

  const { sensorAspectRatio } = getDevice();

  const frustumSize = sensorHeight;
  const frustumHalfSize = frustumSize * 0.5;
  const frustumHalfWidth = frustumHalfSize * sensorAspectRatio;

  const layers = useMemo(() => {
    const layers = new THREE.Layers();
    layers.enable(SENSOR_LAYER);

    return layers;
  }, []);

  return (
    <Canvas shadows={false} dpr={[1, 2]}>
      <OrthographicCamera
        makeDefault
        left={-frustumHalfWidth}
        right={frustumHalfWidth}
        top={frustumHalfSize}
        bottom={-frustumHalfSize}
        position={[0, 0, 20]}
        near={0.1}
        far={500}
        layers={layers}
      />
      <Sensor
        position={[0, 0, 0]}
        filter={getFilter()}
        textureSource={sensorVideoSrc}
        height={sensorHeight}
        width={sensorHeight * sensorAspectRatio}
        playVideo={playVideo}
      />
    </Canvas>
  );
};

export default SensorScene;
