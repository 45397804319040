import { useThree, Camera } from "@react-three/fiber";
import { FunctionComponent, useEffect } from "react";
import { CameraHelper } from "three";

type CameraVisualizerProps = {
  camera?: Camera;
  enabled?: boolean;
};

const CameraVisualizer: FunctionComponent<CameraVisualizerProps> = ({
  camera,
  enabled = true,
}) => {
  const { scene, camera: defaultCamera } = useThree();

  useEffect(() => {
    if (!enabled) return;

    const helper = new CameraHelper(camera ?? defaultCamera);
    scene.add(helper);

    return () => {
      scene.remove(helper);
    };
  }, [camera, defaultCamera, enabled, scene]);

  return null;
};

export default CameraVisualizer;
