import { useThree } from "@react-three/fiber";
import { useEffect, useRef } from "react";

const CameraPositionLogger = () => {
  const { camera } = useThree();
  const cameraRef = useRef(camera);

  useEffect(() => {
    const logCameraPosition = () => {
      const { x, y, z } = cameraRef.current.position;

      const roudedX = Math.round(x * 100) / 100;
      const roudedY = Math.round(y * 100) / 100;
      const roudedZ = Math.round(z * 100) / 100;

      console.log("position", roudedX, roudedY, roudedZ);
      console.log("rotation", cameraRef.current.rotation);
    };

    cameraRef.current = camera;
    window.addEventListener("keypress", (event) => {
      if (event.key === "p") logCameraPosition();
    });

    return () => window.removeEventListener("keypress", logCameraPosition);
  }, [camera]);

  return null;
};

export default CameraPositionLogger;
