import { FunctionComponent, createContext, useState } from "react";

import Sources from "../data/sources.json";

type SettingsContextType = {
  sensorVideoSrc: string;
  setSensorVideoSrc: (sensorVideoSrc: string) => void;
  freeCameraMovement: boolean;
  setFreeCameraMovement: (freeCameraMovement: boolean) => void;
};

const defaultSettingsContext: SettingsContextType = {
  sensorVideoSrc: "",
  setSensorVideoSrc: (sensorVideoSrc: string) => {},
  freeCameraMovement: false,
  setFreeCameraMovement: (freeCameraMovement: boolean) => {},
};

export const SettingsContext = createContext<SettingsContextType>(
  defaultSettingsContext,
);

export const SettingsProvider: FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [sensorVideoSrc, setSensorVideoSrc] = useState<string>(
    Sources[0].source,
  );
  const [freeCameraMovement, setFreeCameraMovement] = useState<boolean>(false);

  const settings = {
    sensorVideoSrc,
    setSensorVideoSrc,
    freeCameraMovement,
    setFreeCameraMovement,
  };

  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  );
};
