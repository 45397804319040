import styled from "@emotion/styled";
import * as Slider from "@radix-ui/react-slider";

export const SliderRoot = styled(Slider.Root)`
  & {
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;
    touch-action: none;
    width: 100%;
    height: 20px;
  }
`;

export const SliderTrack = styled(Slider.Track)`
  & {
    background-color: var(--white-a6);
    position: relative;
    flex-grow: 1;
    border-radius: 9999px;
    height: 3px;
  }
`;

export const SliderRange = styled(Slider.Range)`
  & {
    background-color: var(--white-a12);
    position: absolute;
    height: 100%;
    border-radius: 9999px;
  }
`;

export const SliderThumb = styled(Slider.Thumb)`
  & {
    display: block;
    width: 20px;
    height: 20px;
    background-color: white;
    box-shadow: 0 2px 10px var(--black-a7);
    border-radius: 10px;
  }

  &:hover {
    background-color: var(--white-a12);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 5px var(--black-a8);
  }
`;
