import { ReactThreeFiber } from "@react-three/fiber";
import { FunctionComponent, useMemo, useRef } from "react";
import * as THREE from "three";

import { GradientColorizeFrag, GradientColorizeVert } from "./shaders";
import { useColorGradientTexture } from "./hooks";
import { SENSOR_LAYER } from "../../constants";
import { useHLSVideoTexture } from "../../hooks/useHLSVideoTexture";

type SensorProps = ReactThreeFiber.MeshProps & {
  width: number;
  height: number;
  textureSource: string;
  texture?: THREE.VideoTexture;
  textureScale?: number;
  filter?: Array<THREE.Color>;
  playVideo?: boolean;
};

const Sensor: FunctionComponent<SensorProps> = ({
  width,
  height,
  textureSource,
  texture,
  textureScale = 1,
  filter = [new THREE.Color("black"), new THREE.Color("white")],
  playVideo = true,
  ...rest
}) => {
  const planeRef = useRef<THREE.PlaneGeometry>(null);
  const shaderMaterialRef = useRef<THREE.ShaderMaterial>(null);

  const videoTexture = useHLSVideoTexture(textureSource, {
    autoplay: true,
    muted: true,
    loop: true,
    start: playVideo,
  }) as THREE.VideoTexture;

  const colorGradientTexture = useColorGradientTexture(filter);

  const uniforms = useMemo(() => {
    const scale = Math.max(1, textureScale);

    return {
      u_texture: { value: videoTexture },
      u_color_gradient: { value: colorGradientTexture },
      u_scale: { value: [1 / scale, 1 / scale] },
    };
  }, [textureScale, videoTexture, colorGradientTexture]);

  return (
    <mesh {...rest} layers={SENSOR_LAYER}>
      <planeGeometry ref={planeRef} args={[width, height]} />
      <shaderMaterial
        key={`${colorGradientTexture.uuid}-${textureScale}-${videoTexture.uuid}`}
        ref={shaderMaterialRef}
        attach="material"
        fragmentShader={GradientColorizeFrag}
        vertexShader={GradientColorizeVert}
        uniforms={uniforms}
      />
    </mesh>
  );
};

export default Sensor;
