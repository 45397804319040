import * as THREE from "three";

export function interpolateColor(
  t: number,
  colors: Array<THREE.Color>,
): THREE.Color {
  if (colors.length < 2) {
    throw new Error("At least two colors are required for interpolation.");
  }

  // Scale t to the number of intervals between colors
  const scaledT = t * (colors.length - 1);
  const i = Math.floor(scaledT);
  const frac = scaledT - i;

  // Handle edge cases
  if (i >= colors.length - 1) return colors[colors.length - 1];
  if (i < 0) return colors[0];

  // Interpolate between two colors
  const c1 = colors[i];
  const c2 = colors[i + 1];

  const color = new THREE.Color();
  color.r = c1.r + frac * (c2.r - c1.r);
  color.g = c1.g + frac * (c2.g - c1.g);
  color.b = c1.b + frac * (c2.b - c1.b);

  return color;
}
