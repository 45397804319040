import devices from "../data/devices.json";

export type AvailableDevicesType = keyof typeof devices;

export type DeviceType = (typeof devices)[AvailableDevicesType];

export const deviceKeys = Object.keys(devices) as Array<AvailableDevicesType>;

export const devicesArray = deviceKeys.map((key) => ({ key, ...devices[key] }));

export const initialDevice = deviceKeys[0];

export const digitalZoomOptions = devicesArray.reduce(
  (acc, device) => ({
    ...acc,
    [device.key]: Object.entries(device.digitalZoom).map(
      ([key, value]) => value.value,
    ),
  }),
  {} as Record<AvailableDevicesType, Array<number>>,
);

export type DigitalZoomType =
  (typeof devices)[AvailableDevicesType]["digitalZoom"][number];

export { devices };
