import { Color } from "three";

const whiteHot = [new Color(0, 0, 0), new Color(1, 1, 1)];

const blackHot = [new Color(1, 1, 1), new Color(0, 0, 0)];

const ironbow = [
  new Color(0, 0, 0),
  new Color(0.13, 0, 0.55),
  new Color(0.8, 0, 0.47),
  new Color(1, 0.84, 0),
  new Color(1, 1, 1),
];

const coldGreen = [new Color(0, 1, 0), new Color(0, 0, 0)];

const greenHot = [new Color(0, 0, 0), new Color(0, 1, 0)];

const coldRed = [new Color(1, 0, 0), new Color(0, 0, 0)];

const redHot = [new Color(0, 0, 0), new Color(1, 0, 0)];

const coldRedBoost = [new Color(0.76, 0, 0), new Color(0, 0, 0)];

const hottest = [
  new Color(0, 0, 0),
  new Color(0.5, 0.5, 0.5),
  new Color(1, 1, 0),
];

const hot = [
  new Color(0.0417, 0.0, 0.0),
  new Color(0.125, 0.0, 0.0),
  new Color(0.2083, 0.0, 0.0),
  new Color(0.2917, 0.0, 0.0),
  new Color(0.375, 0.0, 0.0),
  new Color(0.4583, 0.0, 0.0),
  new Color(0.5417, 0.0, 0.0),
  new Color(0.625, 0.0, 0.0),
  new Color(0.7083, 0.0, 0.0),
  new Color(0.7917, 0.0, 0.0),
  new Color(0.875, 0.0, 0.0),
  new Color(0.9583, 0.0, 0.0),
  new Color(1.0, 0.0417, 0.0),
  new Color(1.0, 0.125, 0.0),
  new Color(1.0, 0.2083, 0.0),
  new Color(1.0, 0.2917, 0.0),
  new Color(1.0, 0.375, 0.0),
  new Color(1.0, 0.4583, 0.0),
  new Color(1.0, 0.5417, 0.0),
  new Color(1.0, 0.625, 0.0),
  new Color(1.0, 0.7083, 0.0),
  new Color(1.0, 0.7917, 0.0),
  new Color(1.0, 0.875, 0.0),
  new Color(1.0, 0.9583, 0.0),
  new Color(1.0, 1.0, 0.0625),
  new Color(1.0, 1.0, 0.1875),
  new Color(1.0, 1.0, 0.3125),
  new Color(1.0, 1.0, 0.4375),
  new Color(1.0, 1.0, 0.5625),
  new Color(1.0, 1.0, 0.6875),
  new Color(1.0, 1.0, 0.8125),
  new Color(1.0, 1.0, 0.9375),
];

const jet = [
  new Color(0.0, 0.0, 0.5625),
  new Color(0.0, 0.0, 0.6875),
  new Color(0.0, 0.0, 0.8125),
  new Color(0.0, 0.0, 0.9375),
  new Color(0.0, 0.0625, 1.0),
  new Color(0.0, 0.1875, 1.0),
  new Color(0.0, 0.3125, 1.0),
  new Color(0.0, 0.4375, 1.0),
  new Color(0.0, 0.5625, 1.0),
  new Color(0.0, 0.6875, 1.0),
  new Color(0.0, 0.8125, 1.0),
  new Color(0.0, 0.9375, 1.0),
  new Color(0.0625, 1.0, 0.9375),
  new Color(0.1875, 1.0, 0.8125),
  new Color(0.3125, 1.0, 0.6875),
  new Color(0.4375, 1.0, 0.5625),
  new Color(0.5625, 1.0, 0.4375),
  new Color(0.6875, 1.0, 0.3125),
  new Color(0.8125, 1.0, 0.1875),
  new Color(0.9375, 1.0, 0.0625),
  new Color(1.0, 0.9375, 0.0),
  new Color(1.0, 0.8125, 0.0),
  new Color(1.0, 0.6875, 0.0),
  new Color(1.0, 0.5625, 0.0),
  new Color(1.0, 0.4375, 0.0),
  new Color(1.0, 0.3125, 0.0),
  new Color(1.0, 0.1875, 0.0),
  new Color(1.0, 0.0625, 0.0),
  new Color(0.9375, 0.0, 0.0),
  new Color(0.8125, 0.0, 0.0),
  new Color(0.6875, 0.0, 0.0),
  new Color(0.5625, 0.0, 0.0),
];

export const filters = {
  "Red Hot": redHot,
  "Cold Red": coldRed,
  "Cold Green": coldGreen,
  "Green Hot": greenHot,
  "Black Hot": blackHot,
  "White Hot": whiteHot,
  Ironbow: ironbow,
  "Cold Red Boost": coldRedBoost,
  Hot: hot,
  Hottest: hottest,
  Rainbow: jet,
};

export const filtersKeys = Object.keys(filters) as Array<AvailableFiltersType>;

export const initialFilter = filtersKeys[0];

export const getFilter = (filter: keyof typeof filters) => {
  return filters[filter];
};

export type AvailableFiltersType = keyof typeof filters;
