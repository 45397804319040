import { FunctionComponent, useContext } from "react";
import {
  DialogRoot,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  SelectRoot,
  SelectTrigger,
  SelectContent,
  SelectItem,
  Text,
  Flex,
  Switch,
  Separator,
} from "@radix-ui/themes";

import { SettingsContext } from "../../context/SettingsContext";
import Sources from "../../data/sources.json";
import { Button } from "../ui";

const Settings: FunctionComponent = () => {
  const {
    sensorVideoSrc,
    setSensorVideoSrc,
    freeCameraMovement,
    setFreeCameraMovement,
  } = useContext(SettingsContext);

  return (
    <DialogRoot>
      <DialogTrigger>
        <Button>Settings</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>Settings</DialogTitle>
        <Separator my="3" size="4" />

        <label>Sensor Source</label>
        <div>
          <SelectRoot
            value={sensorVideoSrc}
            onValueChange={(value) => setSensorVideoSrc(value)}
          >
            <SelectTrigger />
            <SelectContent>
              {Sources.map(({ name, source }) => (
                <SelectItem key={source} value={source}>
                  {name}
                </SelectItem>
              ))}
            </SelectContent>
          </SelectRoot>
        </div>
        <Separator my="3" size="4" />

        <Text as="label" size="2">
          <Flex gap="2">
            <Switch
              radius="full"
              checked={freeCameraMovement}
              onCheckedChange={() => setFreeCameraMovement(!freeCameraMovement)}
            />{" "}
            Free Camera Movement
          </Flex>
        </Text>

        {/* <DialogClose>
          <IconButton
            radius="full"
            aria-label="Close"
            style={{ position: "absolute", top: 0, right: 0 }}
          >
            <Cross2Icon />
          </IconButton>
        </DialogClose> */}
      </DialogContent>
    </DialogRoot>
  );
};

export default Settings;
