import { useMemo } from "react";
import * as THREE from "three";

import { interpolateColor } from "./utils";

export const useColorGradientTexture = (gradient: Array<THREE.Color>) =>
  useMemo(() => {
    const size = 256; // The size of the texture (number of colors)
    const data = new Uint8Array(4 * size); // 4 for RGBA

    for (let i = 0; i < size; i++) {
      const stride = i * 4;
      // Assuming you have a function to get color based on a percentage
      const color = interpolateColor(i / size, gradient);

      data[stride] = color.r * 255; // Red
      data[stride + 1] = color.g * 255; // Green
      data[stride + 2] = color.b * 255; // Blue
      data[stride + 3] = 255; // Alpha
    }

    const texture = new THREE.DataTexture(data, size, 1, THREE.RGBAFormat);
    texture.needsUpdate = true;

    return texture;
  }, [gradient]);
