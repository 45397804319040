import { Circle, Tube } from "@react-three/drei";
import { FunctionComponent, useMemo } from "react";
import { LineCurve3, MeshBasicMaterial, Vector3Tuple } from "three";
import { Vector3 as ThreeVector3 } from "three";
import { yAxisRotation180InRad } from "../../utils";
import { DEG2RAD } from "three/src/math/MathUtils";

type HousingProps = {
  position: Vector3Tuple;
};

const Housing: FunctionComponent<HousingProps> = ({ position }) => {
  const material = new MeshBasicMaterial({
    color: 0x000000,
  });

  const curve = useMemo(() => {
    return new LineCurve3(new ThreeVector3(0, 0, 0), new ThreeVector3(0, 0, 1));
  }, []);

  return (
    <group position={position} rotation={yAxisRotation180InRad}>
      {/* <Tube
        args={[curve, 32, 0.4, 32]}
        position={position}
        material={material}
      /> */}
      <Circle args={[0.4, 32, 32]} material={material} />
    </group>
  );
};

export default Housing;
