import { CameraControls } from "@react-three/drei";
import { RootState } from "@react-three/fiber";
import {
  FunctionComponent,
  startTransition,
  useContext,
  useState,
} from "react";
import { Flex, Select, Text } from "@radix-ui/themes";
import { DEG2RAD } from "three/src/math/MathUtils";

import {
  DEVICE_LOOK_AT,
  SCOPE_CAMERA_POSITION,
  SCOPE_LOOK_AT,
} from "../../constants";
import { ControlsContext } from "../../context/ControlsContext";
import {
  AvailableDevicesType,
  AvailableFiltersType,
  devicesArray,
  filtersKeys,
  scopeKeys,
} from "../../controls";
import { LayoutContext } from "../../context/LayoutContext";
import {
  Button,
  SliderRange,
  SliderRoot,
  SliderThumb,
  SliderTrack,
} from "../ui";
import Settings from "../Settings";
import Logo from "../ui/Logo";

type ControlsProps = {
  cameraControls: React.MutableRefObject<CameraControls | null>;
  canvasState: RootState | null;
};

const Controls: FunctionComponent<ControlsProps> = ({ cameraControls }) => {
  const { setShowSensorView } = useContext(LayoutContext);
  const [cameraIsMoving, setCameraIsMoving] = useState(false);
  const {
    zoom,
    setZoom,
    digitalZoom,
    changeDigitalZoom,
    getDigitalZoomOptions,
    playVideo,
    setPlayVideo,
    filter,
    setFilter,
    device,
    changeDevice,
    scope,
    changeScope,
    getScopeScaleFactor,
  } = useContext(ControlsContext);

  const executeWithCameraMoving = async (handler: () => Promise<void>) => {
    setCameraIsMoving(true);
    try {
      await handler();
    } finally {
      setCameraIsMoving(false);
    }
  };

  const handleLeftSideButtonClick = async () => {
    setShowSensorView(false);
    cameraControls.current?.setTarget(...DEVICE_LOOK_AT);
    await cameraControls.current?.setPosition(-0.15, 0.4, 1, true);
  };

  const handleRightSideButtonClick = async () => {
    setShowSensorView(false);
    cameraControls.current?.setTarget(...DEVICE_LOOK_AT);
    await cameraControls.current?.setPosition(-0.15, 0.4, -1, true);
  };

  const handleTopSideButtonClick = async () => {
    setShowSensorView(false);
    cameraControls.current?.setTarget(...DEVICE_LOOK_AT);
    cameraControls.current?.setPosition(0, 1.2, 0, true);
    await cameraControls.current?.rotateTo(DEG2RAD * 90, 0, true);
  };

  const handleScopeButtonClick = async () => {
    await cameraControls.current?.setPosition(...SCOPE_CAMERA_POSITION, true);
    await cameraControls.current?.setTarget(...SCOPE_LOOK_AT);
    setShowSensorView(true);
  };

  const handleDeviceChange = (device: string) => {
    startTransition(() => {
      changeDevice(device as AvailableDevicesType);
    });
  };

  return (
    <>
      <Flex
        direction="column"
        gap="2"
        style={{ position: "absolute", zIndex: 2, top: "1rem", left: "1rem" }}
      >
        <Flex justify="center">
          <a href="https://h7.de" target="_blank" rel="noreferrer">
            <Logo height="4rem" color="white" />
          </a>
        </Flex>
        <Button
          onClick={() => executeWithCameraMoving(handleLeftSideButtonClick)}
          disabled={cameraIsMoving}
        >
          Left Side
        </Button>
        <Button
          onClick={() => executeWithCameraMoving(handleRightSideButtonClick)}
          disabled={cameraIsMoving}
        >
          Right Side
        </Button>
        <Button
          onClick={() => executeWithCameraMoving(handleTopSideButtonClick)}
          disabled={cameraIsMoving}
        >
          Top Side
        </Button>
        <Button
          onClick={() => executeWithCameraMoving(handleScopeButtonClick)}
          disabled={cameraIsMoving}
        >
          Scope
        </Button>
        <br />

        <label htmlFor="device" style={{ color: "white" }}>
          Device
        </label>
        <Select.Root value={device} onValueChange={handleDeviceChange}>
          <Select.Trigger />
          <Select.Content>
            {devicesArray.map((device) => (
              <Select.Item key={device.key} value={device.key}>
                {device.displayName}
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>

        <label htmlFor="scope" style={{ color: "white" }}>
          Scope
        </label>
        <Select.Root
          value={scope.scopeName}
          onValueChange={(value) => changeScope(value)}
        >
          <Select.Trigger />
          <Select.Content>
            {scopeKeys.map((scope) => (
              <Select.Item key={scope} value={scope}>
                {scope}
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>

        <label htmlFor="zoom" style={{ color: "white" }}>
          Zoom
        </label>
        <Flex direction="row" gap="2" align="center">
          <Text style={{ color: "white" }}>{scope.minMagnification}x</Text>
          <SliderRoot
            min={getScopeScaleFactor().min}
            max={getScopeScaleFactor().max}
            step={0.1}
            value={[zoom]}
            onValueChange={(value) => setZoom(value[0])}
            id="zoom"
          >
            <SliderTrack>
              <SliderRange />
            </SliderTrack>
            <SliderThumb />
          </SliderRoot>
          <Text style={{ color: "white" }}>{scope.maxMagnification}x</Text>
        </Flex>

        <label htmlFor="digitalZoom" style={{ color: "white" }}>
          Digital Zoom
        </label>
        <Select.Root
          value={String(digitalZoom.value)}
          onValueChange={(value) => changeDigitalZoom(parseFloat(value))}
        >
          <Select.Trigger />
          <Select.Content>
            {getDigitalZoomOptions().map((zoom) => (
              <Select.Item key={zoom} value={String(zoom)}>
                {zoom}
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>

        <label htmlFor="filter" style={{ color: "white" }}>
          Filter
        </label>
        <Select.Root
          value={filter}
          onValueChange={(value) => setFilter(value as AvailableFiltersType)}
        >
          <Select.Trigger />
          <Select.Content>
            {filtersKeys.map((filter) => (
              <Select.Item key={filter} value={filter}>
                {filter}
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>
        <br />

        <Button onClick={() => setPlayVideo(!playVideo)}>
          {playVideo ? "Pause Video" : "Play Video"}
        </Button>

        <Settings />
      </Flex>
    </>
  );
};

export default Controls;
