import { FunctionComponent, useMemo } from "react";
import { Vector3 } from "@react-three/fiber";
import { Circle, useTexture } from "@react-three/drei";
import { MeshBasicMaterial } from "three";

import { yAxisRotation180InRad } from "../../utils";

type ReticleProps = {
  position: Vector3;
  scale?: Vector3;
  textureSrc?: string;
};

const Reticle: FunctionComponent<ReticleProps> = ({
  position,
  scale,
  textureSrc = "/reticle.png",
}) => {
  const texture = useTexture(textureSrc);

  const material = useMemo(() => {
    return new MeshBasicMaterial({
      map: texture,
      transparent: true,
    });
  }, [texture]);

  return (
    <Circle
      args={[0.4]}
      position={position}
      rotation={yAxisRotation180InRad}
      material={material}
      scale={scale}
    />
  );
};

export default Reticle;
