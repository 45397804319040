import { useGLTF } from "@react-three/drei";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import {
  LinearFilter,
  Object3D,
  Vector3Tuple,
  Vector3,
  Group,
  Object3DEventMap,
} from "three";

import Housing from "./Housing";
import Reticle from "./Reticle";
import Sensor from "../Sensor";
import Magnification from "../Magnification";
import { yAxisRotation180InRad } from "../../utils";
import { DEFAULT_LAYER } from "../../constants";
import { ControlsContext } from "../../context/ControlsContext";
import { SettingsContext } from "../../context/SettingsContext";

type ModelProps = {
  src: string;
  position?: Vector3Tuple;
  scale?: Vector3Tuple;
  rotation?: Vector3Tuple;
  setScene?: (scene: Group<Object3DEventMap>) => void;
};

const Model: FunctionComponent<ModelProps> = ({
  src,
  position,
  scale,
  rotation,
  setScene,
}) => {
  const { scene, materials } = useGLTF(src);
  const controlsContext = useContext(ControlsContext);
  const { sensorVideoSrc } = useContext(SettingsContext);

  useEffect(() => {
    scene.traverse((child) => {
      if (child.isObject3D) {
        child.layers.set(DEFAULT_LAYER);
      }
    });

    if (setScene) {
      setScene(scene);
    }
  }, [scene, setScene]);

  useEffect(() => {
    Object.values(materials).forEach((material) => {
      // @ts-ignore
      if (material.map) material.map.minFilter = LinearFilter;
    });
  }, [materials]);

  // The point in the scene where the video will be displayed (inside the scope)
  // ToDo: rename Videopane to something like LensRearGlassMountPoint
  const videoPane = scene.getObjectByName("Videopane") as Object3D;
  videoPane.visible = false;

  const [videoPaneWorldPosition, setVideoPaneWorldPosition] = useState(
    new Vector3(),
  );
  useEffect(() => {
    if (videoPane) {
      const worldPosition = videoPane.getWorldPosition(new Vector3());
      setVideoPaneWorldPosition(worldPosition);
    }
  }, [videoPane]);

  const { sensorAspectRatio, model } = controlsContext.getDevice();

  return (
    <group position={position} scale={scale} rotation={rotation}>
      <primitive object={scene} />

      <group
        position={videoPane.position}
        scale={[videoPane.scale.x, videoPane.scale.z, videoPane.scale.y]}
      >
        <Housing position={[0, 0, -0.01]} />
        <Sensor
          position={[0, 0, -0.02]}
          rotation={yAxisRotation180InRad}
          filter={controlsContext.getFilter()}
          textureSource={sensorVideoSrc}
          textureScale={controlsContext.digitalZoom.senorScaleFactor}
          width={model.sensorHeight * sensorAspectRatio}
          height={model.sensorHeight}
          playVideo={controlsContext.playVideo}
        />
        <Magnification
          position={[0, 0, -0.03]}
          cameraPosition={[
            videoPaneWorldPosition.x + 0.01,
            videoPaneWorldPosition.y,
            videoPaneWorldPosition.z,
          ]}
          rotation={yAxisRotation180InRad}
          zoom={controlsContext.zoom}
          cameraHelper={false}
        >
          <circleGeometry args={[0.4, 32, 32]} />
        </Magnification>
        <Reticle position={[0, 0, -0.06]} />
      </group>
    </group>
  );
};

export default Model;
