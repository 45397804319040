import { FunctionComponent, createContext, useState } from "react";
import { Color } from "three";

import {
  filters,
  AvailableFiltersType,
  AvailableDevicesType,
  digitalZoomOptions,
  devices,
  DigitalZoomType,
  ScopeType,
  initialScope,
  initialDevice,
  initialFilter,
  getScopeByKey,
  DeviceType,
} from "../controls";

type ControlsContextType = {
  zoom: number;
  setZoom: (zoom: number) => void;
  digitalZoom: DigitalZoomType;
  changeDigitalZoom: (digitalZoom: number) => void;
  getDigitalZoomOptions: () => Array<number>;
  filter: AvailableFiltersType;
  setFilter: (filter: AvailableFiltersType) => void;
  getFilter: () => Array<Color>;
  device: AvailableDevicesType;
  changeDevice: (device: AvailableDevicesType) => void;
  getDevice: () => DeviceType;
  scope: ScopeType;
  changeScope: (scopeKey: string) => void;
  getScopeScaleFactor: () => { min: number; max: number };
  playVideo: boolean;
  setPlayVideo: (playVideo: boolean) => void;
};

const defaultControlsContext: ControlsContextType = {
  zoom: 1,
  setZoom: (zoom: number) => {},
  digitalZoom: devices[initialDevice].digitalZoom[0],
  changeDigitalZoom: (digitalZoom: number) => {},
  getDigitalZoomOptions: () => [],
  filter: initialFilter,
  setFilter: (filter: AvailableFiltersType) => {},
  getFilter: () => [],
  device: initialDevice,
  changeDevice: (device: string) => {},
  getDevice: () => devices[initialDevice],
  scope: initialScope,
  changeScope: (scopeKey: string) => {},
  getScopeScaleFactor: () => initialScope.scaleFactor[initialDevice],
  playVideo: true,
  setPlayVideo: (playVideo: boolean) => {},
};

export const ControlsContext = createContext<ControlsContextType>(
  defaultControlsContext,
);

export const ControlsProvider: FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [digitalZoom, setDigitalZoom] = useState<DigitalZoomType>(
    devices[initialDevice].digitalZoom[0],
  );
  const [filter, setFilter] = useState<AvailableFiltersType>(initialFilter);
  const [playVideo, setPlayVideo] = useState(true);
  const [device, setDevice] = useState<AvailableDevicesType>(initialDevice);
  const [scope, setScope] = useState<ScopeType>(initialScope);
  const [zoom, setZoom] = useState(initialScope.scaleFactor[device].min);

  const getFilter = () => filters[filter];

  const changeDevice = (device: AvailableDevicesType) => {
    setDevice(device);
    setDigitalZoom(devices[device].digitalZoom[0]);
    setZoom(scope.scaleFactor[device].min);
  };

  const getDevice = () => devices[device];

  const changeScope = (scopeKey: string) => {
    const scope = getScopeByKey(scopeKey);
    setScope(scope);
    setZoom(scope.scaleFactor[device].min);
  };

  const getDigitalZoomOptions = () => digitalZoomOptions[device] || [];

  const changeDigitalZoom = (key: number) => {
    setDigitalZoom((prev) => {
      return devices[device].digitalZoom.find((d) => d.value === key) ?? prev;
    });
  };

  const getScopeScaleFactor = () => scope.scaleFactor[device];

  const controls = {
    zoom,
    setZoom,
    digitalZoom,
    changeDigitalZoom,
    getDigitalZoomOptions,
    filter,
    setFilter,
    getFilter,
    device,
    changeDevice,
    getDevice,
    scope,
    changeScope,
    getScopeScaleFactor,
    playVideo,
    setPlayVideo,
  };

  return (
    <ControlsContext.Provider value={controls}>
      {children}
    </ControlsContext.Provider>
  );
};
