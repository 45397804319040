import { useFrame } from "@react-three/fiber";
import { FunctionComponent } from "react";

type CameraLookAtProps = {
  position: THREE.Vector3Tuple;
  enabled?: boolean;
};

/**
 * Make the camera look at the provided point by default.
 */
const CameraLookAt: FunctionComponent<CameraLookAtProps> = ({
  position,
  enabled = true,
}) => {
  useFrame(({ camera }) => {
    if (!enabled) return;

    camera.lookAt(...position);
  });

  return null;
};

export default CameraLookAt;
