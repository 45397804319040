import * as THREE from "three";
import { useEffect, useRef } from "react";
import { useThree } from "@react-three/fiber";
import { suspend } from "suspend-react";
import Hls from "hls.js";

export function useHLSVideoTexture(src, props) {
  const { unsuspend, start, crossOrigin, muted, loop, ...rest } = {
    unsuspend: "loadedmetadata",
    crossOrigin: "Anonymous",
    muted: true,
    loop: true,
    start: true,
    playsInline: true,
    ...props,
  };
  const videoRef = useRef();
  const gl = useThree((state) => state.gl);

  const createHLSBinding = () => {
    var video = videoRef.current;
    console.log(video);
    var hls = new Hls({ abrEwmaFastLive: 1.0, abrEwmaSlowLive: 3.0 });

    hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
      console.log(
        "manifest loaded, found " + data.levels.length + " quality level",
      );
    });
    hls.attachMedia(video);
    hls.loadSource(src);
    hls.on(Hls.Events.MEDIA_ATTACHED, function () {
      console.log("video and hls.js are now bound together !");
    });

    hls.on(Hls.Events.FRAG_CHANGED, function (event, data) {
      var levelDetails = hls.levels[data.frag.level];
      var currentQualityLevel = data.level;
      console.log("Current bitrate:", levelDetails.bitrate, levelDetails);
    });

    hls.on(Hls.Events.LEVEL_SWITCHED, function (event, data) {
      var currentQualityLevel = data.level;
      var totalQualityLevels = hls.levels.length;
      if (props.setBitRate) {
        props.setBitRate(currentQualityLevel + 1);
      }
      console.log(
        `Current quality level: ${
          currentQualityLevel + 1
        }/${totalQualityLevels}`,
      );
    });
  };

  const texture = suspend(
    () =>
      new Promise((res, rej) => {
        const video = Object.assign(document.createElement("video"), {
          src: (typeof src === "string" && src) || undefined,
          srcObject: (src instanceof MediaStream && src) || undefined,
          crossOrigin,
          loop,
          muted,
          ...rest,
        });
        videoRef.current = video;
        if (typeof src === "string") {
          const url = new URL(src, window.location.href);
          if (url.pathname.endsWith(".m3u8") && Hls.isSupported()) {
            createHLSBinding();
          } else {
            console.log("HLS not supported");
          }
        }

        const texture = new THREE.VideoTexture(video);
        if ("colorSpace" in texture) texture.colorSpace = gl.outputColorSpace;
        else texture.encoding = gl.outputEncoding;

        // flip video
        // texture.wrapS = texture.wrapT = THREE.RepeatWrapping
        // texture.repeat.x = -1

        video.addEventListener(unsuspend, () => res(texture));
      }),
    [src],
  );

  useEffect(() => {
    console.log(texture.image);
    start && texture.image.play();

    if (!start) {
      texture.image.pause();
    }
  }, [texture, start]);
  return texture;
}
