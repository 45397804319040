const FixedCameraMagnificationFrag = `
precision mediump float;

uniform sampler2D u_original_texture;
uniform vec2 u_resolution;
uniform vec2 u_zoom_center;
uniform float u_zoom_factor;
uniform vec4 u_fallback_color;
varying vec2 v_uv;

void main() {
  vec2 uv = v_uv;

  // Apply zoom factor around the zoom center
  vec2 dir = uv - u_zoom_center;
  uv = u_zoom_center + u_zoom_factor * dir;

  if (uv.x < 0.0 || uv.x > 1.0 || uv.y < 0.0 || uv.y > 1.0) {
    gl_FragColor = u_fallback_color;
  } else {
    // Sample the texture
    vec4 color = texture2D(u_original_texture, uv);

    gl_FragColor = color;
  }
}`;

export { FixedCameraMagnificationFrag };
