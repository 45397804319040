import { Euler } from "three";
import { DEG2RAD } from "three/src/math/MathUtils";
import CameraControls from "camera-controls";
import { MouseButtons, Touches } from "camera-controls/dist/types";

export const isBrowser = typeof window !== "undefined";

export const yAxisRotation180InRad = new Euler(0, DEG2RAD * 180, 0);

export const toggleCameraControlsUserInput = (enabled: boolean) => {
  if (enabled) {
    const mouseButtons: MouseButtons = {
      left: CameraControls.ACTION.ROTATE,
      middle: CameraControls.ACTION.DOLLY,
      right: CameraControls.ACTION.TRUCK,
      wheel: CameraControls.ACTION.DOLLY,
    };

    const touches: Touches = {
      one: CameraControls.ACTION.TOUCH_ROTATE,
      two: CameraControls.ACTION.TOUCH_DOLLY_TRUCK,
      three: CameraControls.ACTION.TOUCH_TRUCK,
    };

    return { mouseButtons, touches };
  } else {
    const mouseButtons: MouseButtons = {
      left: CameraControls.ACTION.NONE,
      middle: CameraControls.ACTION.NONE,
      right: CameraControls.ACTION.NONE,
      wheel: CameraControls.ACTION.NONE,
    };

    const touches: Touches = {
      one: CameraControls.ACTION.NONE,
      two: CameraControls.ACTION.NONE,
      three: CameraControls.ACTION.NONE,
    };

    return { mouseButtons, touches };
  }
};
