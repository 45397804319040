import scopes from "../data/scopes.json";

export const initialScope = scopes[0];

export type ScopeType = typeof initialScope;

export const scopeKeys = scopes.map((scope) => scope.scopeName);

export const getScopeByKey = (key: string): ScopeType => {
  return scopes.find((scope) => scope.scopeName === key) ?? initialScope;
};
