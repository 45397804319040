import styled from "@emotion/styled";
import MainScene from "./components/MainScene";
import SensorScene from "./components/SensorScene";
import { LayoutContext } from "./context/LayoutContext";
import { useContext } from "react";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
`;

const MainViewWrapper = styled.div`
  height: 100vh;
`;

export const SensorViewWrapper = styled.div`
  margin: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  width: 400px;
  height: 300px;
  z-index: 2;

  @media (max-width: 1280px) {
    width: 300px;
    height: 225px;
  }
`;

function App() {
  const { showSensorView, mainSceneReady } = useContext(LayoutContext);

  return (
    <Wrapper>
      <MainViewWrapper>
        <MainScene />
      </MainViewWrapper>
      {showSensorView && mainSceneReady && (
        <SensorViewWrapper>
          <SensorScene />
        </SensorViewWrapper>
      )}
    </Wrapper>
  );
}

export default App;
