const GradientColorizeFrag = `
precision mediump float;

varying vec2 v_uv;
uniform vec2 u_scale;
uniform sampler2D u_texture;
uniform sampler2D u_color_gradient;

void main() {
    // Translate so that the center is the origin
    vec2 centered_uv = v_uv - vec2(0.5, 0.5);

    // Scale
    centered_uv *= u_scale;

    // Translate back
    vec2 scaled_uv = centered_uv + vec2(0.5, 0.5);

    // Wrap the coordinates to repeat the texture
    scaled_uv = fract(scaled_uv);

    // Get the intensity from the black and white texture
    float intensity = texture2D(u_texture, scaled_uv).r;

    // Use the intensity to sample from the gradient
    vec4 color = texture2D(u_color_gradient, vec2(intensity, 0.5));

    gl_FragColor = color;
}
`;

export { GradientColorizeFrag };
